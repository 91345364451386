.section-nav {
  // border: 1px solid $debugColor;
  font-size: 1rem;
  &:lang(ru-ru) {
    font-family: Arial, sans-serif;
  }

  ul {
    text-align: right;
    list-style-type: none;
    padding: 0;
    margin: 0;
    text-transform: uppercase;

    li {
      vertical-align: top;
      display: inline-block;
      padding: 0.5rem 0.25rem;
      margin: 0.5rem;
      &:hover {
        cursor: pointer;
      }
      .home {
        float: left;
        height: 20px;
        width: 20px;
        background-image: url(../../assets/images/home.svg);
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: 20px 20px;
        text-indent: 50px;
        overflow: hidden;
      }
    }
  }
  .current {
    font-family: MetricHPEBold;
    font-style: normal;
    font-weight: normal;
    &:lang(ru-ru) {
      font-weight: bold;
      font-family: Arial, sans-serif;
    }
  }
}
