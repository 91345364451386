.control {
  padding: 3rem;
  font-size: 2rem;
  a {
    border: 1px solid blue;
    padding: 1rem;
    margin: 0;
    border-radius: 1rem;
  }
}

.print {
  position: relative;
  border: 1px dotted $debugColor;

  select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    width: inherit;
  }

  // Hide the second dropdown, language
  .callout.intro {
    .dropdown:nth-of-type(2n) {
      display: none;
    }
  }

  .filter-group {
    select {
      width: 200px;
    }
    .additional {
      display: none;
    }
    h3 {
      .normal {
        display: none;
      }
      .print {
        display: block;
        border: none;
      }
    }
  }
  .inverse {
    .current {
      border-color: #fff;
    }
  }
  header {
    display: block;
    nav {
      display: inline-block;
      position: absolute;
      white-space: nowrap;
      top: 2rem;
      right: 1rem;
      padding: 0;
      margin: 0;
      font-size: 1rem;
      #nav-home {
        display: none;
      }
      .current {
        border-bottom: 1px solid #000;
      }
      li {
        padding: 0;
        margin: 0 0 0 1.5rem;
      }
    }
  }
  .button {
    display: none;
  }

  footer {
    .section-nav {
      // display: none;
    }
    // Disclaimer
    .disclaimer {
      padding-right: 2rem;
    }
  }
  .section-detail {
    .overlay {
      animation: none;
      .callout {
        animation: none;
      }
      .grid {
        // display: none !important;
        .grid-group {
          .grid-item {
            width: 92%;
            height: 110px !important;
            &.download {
              display: none;
            }
            .button {
              display: block;
              button {
                bottom: 15px;
                // display: block;
                display: none;
              }
            }
            .cta-location {
              padding: 1rem 0 0 0;
              display: block;
              position: absolute;
              bottom: 5px;
              a {
                color: $primaryBrandColor;
              }
            }
          }
        }
      }
    }
  }
  .graph {
    animation: none;
  }

  // Wrapper for core content, to separate it from background image
  .content {
    position: relative;
    height: 920px;
    margin-bottom: 100px;
    &.inverse {
      color: $inversePrimaryTextColor;

      footer {
        color: $primaryTextColor;
      }
    }
  }

  .data-summary {
    dl {
      dd {
        animation: none;
        transition: none;
      }
    }
  }

  .bg {
    // border: 1px solid $debugColor;
    position: absolute;
    display: block;
    z-index: 1;
    top: 0;
    left: 0;
    width: 1400px;
    height: 820px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50% 50%;
  }
  .page-number {
    display: inline-block;
    width: 2rem;
    font-family: MetricHPEBold;
    // &:before {
    //   content: '/ ';
    // }
  }
}

@media print {
  .print {
    border: none;
  }
  .control {
    display: none;
  }
  .footnote:after {
    // content: 'hpe.com';
  }
  @page {
  }
  .content {
    // border: 1px solid $debugColor;
    page-break-before: always;
  }
}
