@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  15% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.section {
  position: relative;
  margin: 0 auto;
  width: 1400px;
  height: 1000px;

  // Header
  header {
    padding: 2rem 1rem 2rem 2rem;
    font-size: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    h2 {
      text-transform: uppercase;
      &:hover {
        cursor: pointer;
      }
      &.default:hover {
        cursor: default;
      }
    }
    .primary-logo {
      position: absolute;
      right: 3rem;
      width: 200px;
      height: 83px;
      background-image: url(../../assets/images/logo-primary.png);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: 50% 50%;
    }
  }

  // Footer
  footer {
    border-top: 1px solid $primaryLineColor;
    position: relative;
    padding: 1.5rem 0 0 0;
    display: flex;
    flex-direction: column;
    color: $primaryTextColor;

    // Disclaimer
    .disclaimer {
      span {
        display: inline-block;
        padding-left: 0.5rem;
        &:after {
          content: '.';
        }
      }
    }

    // Foot note at bottom
    .footnote {
      text-align: right;
      padding: 1rem 0.8rem 0 0;
    }

    // Secondary logo placement
    .secondary-logo {
      position: absolute;
      left: 1.5rem;
      top: 2rem;
      width: 125px;
      height: 38px;
      background-image: url(../../assets/images/logo-secondary.png);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: 50% 50%;
    }
  }

  // Body wrapper
  article.main {
    // border: 1px solid green;
    display: block;
    position: relative;
    height: 675px;
  }

  // Body wrapper
  .restricted-group {
    // border: 1px solid green;
    display: block;
    overflow: hidden;
    height: 680px;
    position: relative;
  }

  // Callout detail
  .section-detail {
    // border: 10px solid blue;
    // height: 900px;
    position: relative;
    display: block;
    padding: 0;
    text-align: left;

    /* Callout content */
    .overlay {
      // border: 1px solid blue;
      animation: fadeIn ease 1s; // Remove this for print
      position: absolute;
      display: block;
      z-index: 5;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      .callout {
        // border: 1px dotted $debugColor;
        position: absolute;
        top: 0;
        left: 20px;
        width: 700px;
        padding: 12px;
        animation: fadeIn ease 1s; // Remove this for print

        // Helps with widows
        span {
          white-space: nowrap;
        }

        &.intro {
          .description {
            font-size: 1.5rem;
            line-height: 1.75rem;
            &:lang(ru-ru) {
              font-size: 1.25rem;
              line-height: 1.5rem;
            }
          }
          select {
            width: inherit;
          }
        }

        &.significant {
          border-left: 1px solid $primaryLineColor;

          .optional {
            border-left: 1px solid $secondaryLineColor;
            border-top: none;
            border-bottom: none;
          }

          h3 {
            // font-size: 1.4rem;
          }
        }
      }

      .description {
        white-space: pre-line;
        font-size: 1.25rem;
        line-height: 1.5rem;

        padding: 0.5rem 0 0 0;

        &:lang(ru-ru) {
          font-size: 1rem;
          line-height: 1.25rem;
          font-family: Arial, sans-serif;
        }

        span {
          white-space: nowrap;
        }

        em {
          font-family: MetricHPEBold;
          font-style: normal;
          font-weight: normal;
          &:lang(ru-ru) {
            font-weight: bold;
            font-family: Arial, sans-serif;
          }
        }
      }
      .optional {
        border-top: 1px solid $secondaryLineColor;
        border-bottom: 1px solid $secondaryLineColor;
        padding: 0.5rem 0;
        margin: 1rem 0;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        align-items: center;
        align-content: flex-start;

        em {
          font-family: MetricHPEBold;
          font-style: normal;
          font-weight: normal;
          &:lang(ru-ru) {
            font-weight: bold;
            font-family: Arial, sans-serif;
          }
        }

        .icon {
          display: block;
          padding: 0;
          margin: 0 0.25rem 0 0;
          width: 50px;
          height: 50px;
          background-image: url(../../assets/images/info.svg);
          background-repeat: no-repeat;
          background-position: 50% 50%;
          background-size: 25px 25px;
        }
        .tidbit {
          display: block;
          white-space: pre-line;
          font-size: 1rem;
          line-height: 1.25rem;
          width: 90%;
          &:lang(ru-ru) {
            font-size: 0.9rem;
            line-height: 1rem;
            font-family: Arial, sans-serif;
          }
        }
      }
      .note {
        white-space: pre-line;
        color: $debugColor;
        font-size: 1rem;
        padding: 2rem 0 0 0;
        // &::before {
        //   content: 'Note: ';
        // }
      }

      h3 {
        font-family: MetricHPEBold;
        text-transform: uppercase;
        font-size: 1.5rem;
        line-height: 1.5rem;
        font-style: normal;
        font-weight: normal;
        padding: 0 0 0.5rem 0;
        margin: 0;
        border-bottom: 1px solid $primaryLineColor;
        &.summary {
          border: none;
        }
        &:lang(ru-ru) {
          font-size: 1.25rem;
          line-height: 1.4rem;
          font-weight: bold;
          font-family: Arial, sans-serif;
        }
      }
      p {
      }

      .grid {
        display: block;
        // display: flex;
        // flex-direction: column;
        // justify-content: space-between;
        padding: 0;
        margin: 0;
        // border: 1px solid red;

        .description {
        }

        .filter {
          position: relative;
          .filter-group {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 0;
            margin: 0;
            width: 280px;

            .dropdown {
              display: inline-block;
              padding-right: 1rem;
              select {
                min-width: 280px;
              }
            }
          }

          h3 {
            display: none;
          }
        }

        h3 {
          font-size: 1.75rem;
          padding: 0 0 0 0.75rem;
          text-transform: uppercase;
        }

        .grid-group {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          // flex-direction: row;
          // flex-wrap: wrap;
          align-items: flex-start;
          padding: 0 0 0 0;
          margin: 0;
          overflow: hidden;
          height: 670px;

          .grid-item {
            position: relative;
            height: 165px;
            width: 305px;
            border: 8px solid $hpeMediumGray;
            background-color: $cardBgColor;
            color: $primaryTextColor;
            padding: 1rem;
            margin: 0.25rem;
            align-self: flex-start;
            // flex-grow: 2;

            &.download {
              // height: 170px;
            }
            h2 {
              font-family: MetricHPEBold;
              font-weight: normal;
              font-style: normal;
              font-size: 1.25rem;
              &:lang(ru-ru) {
                font-weight: bold;
                font-size: 1rem;
                font-family: Arial, sans-serif;
              }
            }

            .cta-location {
              display: none;
            }

            .description {
              font-size: 1.25rem;
              padding: 0.5rem 0 0 0;
              em {
                font-family: MetricHPERegular;
                font-weight: normal;
                &:lang(ru-ru) {
                  font-size: 1.1rem;
                  font-family: Arial, sans-serif;
                }
              }
            }

            button {
              height: 40px;
              position: absolute;
              bottom: 0;
              left: 1rem;
              font-size: 1.25rem;
              margin: 0 0 1rem 0;
            }
          }
        }
      }
    }
  }

  // Filter
  .filter {
    position: absolute;
    z-index: 10;
    right: 0;
    display: block;
    bottom: 0;
  }

  // Wrapper for core content, to separate it from background image
  .content {
    // border: 1px solid $debugColor;
    position: absolute;
    display: block;
    z-index: 5;
    top: 0;
    left: 0;
    width: 1400px;
    height: 820px;
    color: $primaryTextColor;

    &.inverse {
      color: $inversePrimaryTextColor;

      footer {
        color: $primaryTextColor;
      }
    }
  }

  // Background image content
  .bg {
    // border: 1px solid $debugColor;
    position: absolute;
    display: block;
    z-index: 1;
    top: 0;
    left: 0;
    width: 1400px;
    height: 820px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50% 50%;
  }

  // Visualization/chart content
  .chart {
    // border: 1px solid $debugColor;
    position: absolute;
    display: block;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    height: 700px;
  }
}
