@keyframes move {
  0% {
    width: 0;
  }
}
.data-summary {
  // border: 1px solid red;
  overflow: hidden;

  width: 200px;
  height: 200px;
  padding: 0 0 0 1rem;
  float: right;

  &.summary {
    float: none;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: auto;
    border: 4px solid $hpeMediumBlue;
    margin-top: 0.5rem;
    .overall,
    .current {
      padding-right: 2rem;
      dd {
        font-size: 2.5rem;
        padding: 0.25rem 1rem 0.25rem 1.75rem;
      }
    }
  }

  .overall {
    dd {
      background-color: $primaryBrandColor;
    }
  }
  .current {
    dd {
      background-color: $hpeMediumBlue;
    }
  }
  dl {
    padding: 0.5rem 0 0 0;
    margin: 0;
    text-align: left;
    dt {
      padding: 0;
      margin: 0 0 0.25rem 0;
      color: $primaryTextColor;
      // white-space: nowrap;
      text-transform: uppercase;
    }
    dd {
      transition: all 1s ease; // Remove this for print
      animation: move ease 1s; // Remove this for print
      font-family: MetricHPEBold;
      font-size: 1.5rem;
      color: $inversePrimaryTextColor;
      padding: 0.25rem 0.5rem;
      margin: 0 0 0.5rem 0;
      text-align: right;
    }
  }
}
